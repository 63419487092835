import { render, staticRenderFns } from "./ChatDialog.vue?vue&type=template&id=e5fa9bfe&scoped=true"
import script from "./ChatDialog.vue?vue&type=script&setup=true&lang=js"
export * from "./ChatDialog.vue?vue&type=script&setup=true&lang=js"
import style0 from "./ChatDialog.vue?vue&type=style&index=0&id=e5fa9bfe&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5fa9bfe",
  null
  
)

export default component.exports