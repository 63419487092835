import { ChatClient } from '@azure/communication-chat'
import { AzureCommunicationTokenCredential } from '@azure/communication-common'
import { defineStore } from 'pinia'

import { relaunchApi } from '@/logic/relaunchApi'

async function fetchCredentials() {
  const response = await relaunchApi.get('/chat/credentials')
  return response.data
}
export const useAzureChatStore = defineStore('azureChatStore', {
  state: () => ({
    azureChatClient: null,
    azureChatClientPromise: null,
    accessToken: undefined,
    myIdentity: undefined,
  }),
  actions: {
    async getToken() {
      const data = await fetchCredentials()
      return data.accessToken.token
    },
    async loadCredentials() {
      if (!this.accessToken || this.myIdentity) {
        const data = await fetchCredentials()
        this.accessToken = data.accessToken
        this.myIdentity = data.identity.id
      }
    },
    async getChatClient() {
      if (this.azureChatClientPromise) {
        return this.azureChatClientPromise
      }
      if (this.azureChatClient) {
        return this.azureChatClient
      }
      this.azureChatClientPromise = new Promise((resolve, reject) => {
        this.loadCredentials().then(async () => {
          const endpointUrl = process.env.VUE_APP_AZURE_COMMUNICATION_ENDPOINT
          const tokenCredential = new AzureCommunicationTokenCredential({
            tokenRefresher: async () => this.getToken(),
            refreshProactively: true,
            token: this.accessToken.token,
          })
          this.azureChatClient = await new ChatClient(endpointUrl, tokenCredential)
          return this.azureChatClient
        })
          .then(() => {
            resolve(this.azureChatClient)
            this.azureChatClientPromise = null
          })
          .catch((error) => {
            this.azureChatClient = null
            this.azureChatClientPromise = null
            reject(error)
          })
      })

      return this.azureChatClientPromise
    },
  },
})
