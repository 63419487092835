module.exports = {
  cancel: 'Abbrechen',
  messages: 'Nachrichten',
  no_users_found: 'Keine Benutzer gefunden',
  search: 'Suche',
  search_chat: 'Chat durchsuchen',
  last_messages: 'Letzte Nachrichten ({count})',
  you_do_not_have_any_messages_yet: 'Du hast noch keine Nachrichten.',
  look_and_select_person_to_message: 'Such doch mal nach der Person mit der du am liebsten arbeitest und schreib ihr.',
}
